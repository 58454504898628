import React from "react"
import { Flex, Box } from "rebass"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../components/layout"
import Canvas from "../components/canvas"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithubAlt,
  faCodepen,
  faDribbble,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"

const About = ({ ...props }) => (
  <Layout {...props}>
    <SEO title="About Lim Qian Joo" />
    <Canvas id="about-canvas" />
    <Flex justifyContent="center" p={[2, 3, 4]}>
      <Box width={[1, 5 / 6, 1 / 2]}>
        <Box px={[2, 3, 4]}>
          <h2 className="cu">
            Hello, I'm Qian Joo, a User Experience Designer based in Singapore.
          </h2>
          <h3>
            Self-taught with a background in front-end development and art, I specialise in User Research, Visual Design, and Interaction
            Design.        
          </h3>
        </Box>
        <Flex flexWrap="wrap" mb={[5, 6, 7]} py={[2, 2, 3]}>
          <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
            <h5>Have a design/tech/art adventure you'd like to work on with me?</h5>
            <p>
              Let’s chat:{" "}
              <OutboundLink
                href="https://linkedin.com/in/limqianjoo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
                </span>
              </OutboundLink>
            </p>
          </Box>
          <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
            <h5>You can also find me on</h5>
            <Flex flexWrap="wrap">
              <span style={{ marginBottom: "1rem", paddingRight: "1rem" }}>
                <OutboundLink
                  href="https://dribbble.com/lqjoo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faDribbble} />
                  &nbsp; Dribbble
                </OutboundLink>
              </span>
              <span style={{ marginBottom: "1rem", paddingRight: "1rem" }}>
                <OutboundLink
                  href="https://codepen.io/lqjoo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faCodepen} />
                  &nbsp; Codepen
                </OutboundLink>
              </span>
              <span style={{ marginBottom: "1rem", paddingRight: "1rem" }}>
                <OutboundLink
                  href="https://github.com/limqjoo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faGithubAlt} />
                  &nbsp; Github
                </OutboundLink>
              </span>
              <span style={{ marginBottom: "1rem" }}>
                <OutboundLink
                  href="https://instagram.com/lqjoo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                  &nbsp; Instagram
                </OutboundLink>
              </span>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  </Layout>
)

export default About
