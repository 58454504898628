import React, { useRef, useEffect } from "react"

const Canvas = props => {
  const cRef = useRef(null)
  useEffect(() => {
    const c = cRef.current
    const ctx = c.getContext("2d")
    let paint = false
    let coord = { x: 0, y: 0 }
    const resize = () => {
      c.width = document.body.scrollWidth
      c.height = window.innerHeight
    }
    const getPosition = event => {
      coord.x = event.clientX - c.offsetLeft
      coord.y = event.clientY - c.offsetTop
    }
    const startPainting = event => {
      paint = true
      getPosition(event)
    }
    const stopPainting = () => {
      paint = false
    }

    const sketch = event => {
      if (!paint) return
      ctx.beginPath()

      ctx.lineWidth = 4

      // Sets the end of the lines drawn
      // to a round shape.
      ctx.lineCap = "round"

      ctx.strokeStyle = "#ffb839"

      // The cursor to start drawing
      // moves to this coordinate
      ctx.moveTo(coord.x, coord.y)

      // The position of the cursor
      // gets updated as we move the
      // mouse around.
      getPosition(event)

      // A line is traced from start
      // coordinate to this coordinate
      ctx.lineTo(coord.x, coord.y)

      // Draws the line.
      ctx.stroke()
    }
    /*listen*/
    resize()
    document.addEventListener("mousedown", startPainting)
    document.addEventListener("mouseup", stopPainting)
    document.addEventListener("mousemove", sketch)
    window.addEventListener("resize", resize)
  }, [])

  return <canvas ref={cRef} {...props} />
}

export default Canvas
